import React from "react";
import HeroHeader from "./HeroHeader";
import Prismic from "prismic-javascript";
import {RichText} from "prismic-reactjs";
import {
	FooterBox,
	SectionFeatures,
	SectionFeaturesReverse,
	SectionFeaturesWithBg,
	SectionFeaturesWithBgReverse
} from "../../components";
import Footer from "../../templates/Footer/Footer";
import MiddleSection from "./MiddleSection";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../actions";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const Features = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {

		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'features'),
				{lang: lang}
			)
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang, dispatch])
	if (doc) {
		return (
			<main className={'main-content main-features'}>
					<HeroHeader
						title={doc.data.hero_title}
						description={doc.data.hero_description}
						image={doc.data.hero_image}
						buttonUrl={doc.data.hero_button_link}
						buttonText={RichText.asText(doc.data.hero_button_text)}
					/>
					{
						doc.data.body.map((slice, key) => {
							if (slice.slice_type === 'basic_section') {
								if (!slice.primary.image_position) {
									return(
										<SectionFeatures
											id={RichText.asText(slice.primary.id)}
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
										/>
									)
								} else {
									return(
										<SectionFeaturesReverse
											id={RichText.asText(slice.primary.id)}
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
										/>
									)
								}
							} else if (slice.slice_type === 'basic_section_with_background') {
								if (slice.primary.image_position) {
									return(
										<SectionFeaturesWithBgReverse
											id={RichText.asText(slice.primary.id)}
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
											bg={slice.primary.section_background}
										/>
									)
								} else {
									return(
										<SectionFeaturesWithBg
											id={RichText.asText(slice.primary.id)}
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
											bg={slice.primary.section_background}
										/>
									)
								}
							}
							else {
								return null;
							}
						})
					}
					<MiddleSection
					id={RichText.asText(doc.data.id_middle)}
					title={doc.data.middle_section_title}
					description={doc.data.middle_section_description}
					color={doc.data.middle_section_color}
					background={doc.data.middle_section_background}
					icon={doc.data.middle_section_icon}
					icons={doc.data.middle_section_icons}
					>
						{
							doc.data.body1.map((slice, key) => {
								if (slice.slice_type === 'basic_section_with_background') {
									if (slice.primary.image_postion) {
										return(
											<SectionFeaturesWithBg
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`1-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
												bg={slice.primary.section_background}
											/>
										)
									} else {
										return(
											<SectionFeaturesWithBgReverse
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`1-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
												bg={slice.primary.section_background}
											/>
										)
									}
								}
								else {
									return null;
								}
							})
						}
					</MiddleSection>
					{
						doc.data.body2.map((slice, key) => {
							if (slice.slice_type === 'basic_section') {
								if (slice.primary.image_postion) {
									return(
										<SectionFeaturesReverse
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`2-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
											/>
										)
									} else {
										return(
											<SectionFeatures
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`2-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
											/>
										)
									}
								} else if (slice.slice_type === 'basic_section_with_background') {
									if (slice.primary.image_postion) {
										return(
											<SectionFeaturesWithBgReverse
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`2-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
												bg={slice.primary.section_background}
											/>
										)
									} else {
										return(
											<SectionFeaturesWithBg
												id={RichText.asText(slice.primary.id)}
												key={key}
												no={`2-${key}`}
												title={slice.primary.section_title}
												description={slice.primary.section_description}
												image={slice.primary.section_image}
												icon={slice.primary.section_icon}
												color={slice.primary.section_color}
												accordion={slice.items}
												bg={slice.primary.section_background}
											/>
										)
									}
								}
								else {
									return null;
								}
							})
					}
					<FooterBox lang={props.lang}/>
					<Footer lang={props.lang}/>
				</main>
		);
	}
	return null;
}
export default Features;
