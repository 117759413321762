import Globe from "../../svg/colored/globe.svg";
import {RichText} from "prismic-reactjs";
import {ReactComponent as Arrow} from "../../svg/colored/chevron.svg";
import {Link} from "react-router-dom";
import React from "react";

const LanguageSwitch = (props) => {
	return(
		<div className="main-navigation__lang">
			<div className="main-navigation__lang__icon">
				<img src={Globe} alt={''}/>
			</div>
			<div className="main-navigation__lang__chooser">
				<div className="current-lang">
					{
						props.languages.map((lang, key) => {
							if (RichText.asText(lang.language_name) === props.currentLang) {
								return RichText.asText(lang.language_code);
							}
							return null;
						})
					}
					<div className="main-navigation__lang__chooser__arrow">
						<Arrow/>
					</div>
				</div>
				<div className="lang-list">
					{
						props.languages.map((lang, key) => {
							if (RichText.asText(lang.language_name) !== props.currentLang) {
								return (
									<li className="lang-list__item" key={key}>
										<Link className="lang-list__link" to={`/${(RichText.asText(lang.language_code) === 'nl') ? '' : RichText.asText(lang.language_code)}`}>{RichText.asText(lang.language_code)}</Link>
									</li>
								)
							}
							return null;
						})
					}
				</div>
			</div>
		</div>
	)
}
export default LanguageSwitch;
