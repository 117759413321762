import React from "react";
import Prismic from "prismic-javascript";
import './footer.scss';
import { RichText } from 'prismic-reactjs';
import {NavLink} from "react-router-dom";

const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const Footer = (props) => {
	const [doc, setDocData] = React.useState(null)
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'footer_box'),
				{lang: lang}
			)
			if (response) {
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang])
	if (doc) {

		return (
			<footer className={`main-footer ${props.className ? props.className : ''}`}>
				<div className="container">
					<div className="main-footer__content">
						<div className="main-footer__logo">
							<a href="/">
								<img src={doc.data.header_logo.url} alt="Companyscan"/>
							</a>
						</div>
						<ul className="main-footer__menu">
							{
								doc.data.links.map((link, key) => {
									let url = '#';
									if(link.link_url.link_type === 'Document') {
										if (link.link_url.lang === 'en-gb') {
											url = '/en/' + link.link_url.uid

										} else {
											url = '/' + link.link_url.uid
										}
									} else {
										url = link.link_url.url
									}
									if (key === 0) {
										return(
											<li key={key} className="main-footer__menu__item">
												<a className="main-footer__menu__link" href={url}>
													{RichText.asText(link.link_title)}
												</a>
											</li>
										)
									}
									return(
										<li key={key} className="main-footer__menu__item">
											<NavLink className="main-footer__menu__link" to={url}>
												{RichText.asText(link.link_title)}
											</NavLink>
										</li>
									)
								})
							}
						</ul>
					</div>
				</div>
			</footer>
		)
	}
	return null;
}
export default Footer;
