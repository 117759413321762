import React, {useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
import './navbar.scss';
import { useSelector, useDispatch } from 'react-redux'
import {changeLanguage, openMenu} from "../../actions";
import Prismic from "prismic-javascript";
import {RichText} from "prismic-reactjs";
import {LanguageSwitch} from "../../components";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const NavBar = (props) => {
	const [scrolled, setScrolled] = React.useState(false);
	const location = useLocation();
	useEffect(e => {
		const handleScroll = e => {
			if (document.body.scrollTop >= 280 || document.documentElement.scrollTop >= 280) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		}
		window.addEventListener('scroll', handleScroll)
		return e => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	const [doc, setDocData] = React.useState(null);
	const language = useSelector(state => state.lang);
	const dispatch = useDispatch();

	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'footer_box'),
				{lang: language}
			)
			if (response) {
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [language])

	const menuOpener = useSelector(state => state.menuOpener);
	if (location.pathname !== '/login' && location.pathname !== '/en/login') {
		if (doc) {
			return (
				<>
					<div className={`navbar ${scrolled ? 'scrolled' : ''} ${menuOpener ? 'menu-opened' : ''}`}>
						<div className="container">
							<div className="main-navigation">
								<div className="main-navigation__logo">
									{
										doc.data.nav.map((item,key) => {
											if (key === 0) {
												return(
													<NavLink to={RichText.asText(item.nav_path)} key={key}>
														<img src={doc.data.header_logo.url} alt="Companyscan"/>
													</NavLink>
												)
											}
											return null;
										})
									}
								</div>
								<div className={menuOpener ? 'main-navigation__menu active' : 'main-navigation__menu'}>
									<ul className="main-navigation__menu__list">
										{
											doc.data.nav.map((item, key) => {
												if (item.is_login) {
													return(
														<li className="main-navigation__menu__list__item d-inline-flex d-lg-none" key={key}>
															<NavLink activeClassName={'active'} exact onClick={() => dispatch(openMenu())} className="main-navigation__menu__list__link" to={RichText.asText(item.nav_path)}>{RichText.asText(item.nav_title)}</NavLink>
														</li>
													)
												} else {
													return(
														<li className="main-navigation__menu__list__item" key={key}>
															<NavLink activeClassName={'active'} exact onClick={() => dispatch(openMenu())} className="main-navigation__menu__list__link" to={RichText.asText(item.nav_path)}>{RichText.asText(item.nav_title)}</NavLink>
														</li>
													)
												}
											})
										}
									</ul>
								</div>
								<div className="main-navigation__right">
									<LanguageSwitch
										languages={doc.data.languages}
										currentLang={doc.lang}
									/>
									{
										doc.data.nav.map((item, key) => {
											if (item.is_login) {
												return(
													<a class="main-navigation__login" href="https://app.companyscan.com">Login</a>
												)
											}
											return null;
										})
									}
								</div>
								<div className={menuOpener ? 'main-navigation__trigger active' : 'main-navigation__trigger'} onClick={() => dispatch(openMenu())}><span></span><span></span><span></span></div>
							</div>
						</div>
					</div>
				</>
			)
		}
	}

	return null;
}
export default NavBar;
