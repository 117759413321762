import React from "react";
import Prismic from "prismic-javascript";
import {RichText} from "prismic-reactjs";
import './contact.scss';
import Footer from "../../templates/Footer/Footer";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../actions";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});


const Contact = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'contact'),
				{lang: lang}
			)
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang, dispatch])
	if (doc) {
		return (
			<>
				<main className={'main-content main-contact'}>
					<div className="contact-header">
						<div className="container">
							<div className="row">
								<div className="col-lg-8">
									<h1 className="contact-header__title h2">
										{RichText.asText(doc.data.page_title)}
									</h1>
									<p className="contact-header__description">
										{RichText.asText(doc.data.page_description)}
									</p>
								</div>
							</div>

						</div>
					</div>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="contact-form contact-info">
									{
										doc.data.contact_info.map((info, key) => {
											return(
												<div className="contact-info-group" key={key}>
													<div className="contact-info-group__title h4">
														{RichText.asText(info.contact_info_title)}
													</div>
													<div className="contact-info-group__content">
														{RichText.render(info.contact_info_content)}
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer className={'contact-footer'} lang={props.lang}/>
			</>
		);
	}
	return null;
}
export default Contact;
