import React, {useState} from 'react';

const Input = (props) => {
	let textInput = React.createRef();
	const [val, setVal] = useState(0);

	const checkValue = () => {
		setVal(textInput.current.value);

		if (props.callbackFunc) {
			props.callbackFunc(textInput.current.value, textInput.current.name);
		}
	}

	if (props.type === 'textarea') {
		return(
			<div className="input-group">
				<textarea name={props.name} ref={textInput} placeholder={props.placeholder} id={props.id} onChange={() => checkValue()} className={val.length > 0 ? ' is-filled': ''}/>
				<label htmlFor={props.id}>{props.placeholder} {props.required ? '*' : ''}</label>
			</div>
		)
	} else {
		return(
			<div className={`input-group ${props.className ? props.className : ''}`}>
				<input name={props.name} ref={textInput} type={props.type || 'text'} placeholder={props.placeholder} id={props.id} onChange={() => checkValue()} className={val.length > 0 ? ' is-filled': ''} required={props.required}/>
				<label htmlFor={props.id}>{props.placeholder} {props.required ? '*' : ''}</label>
			</div>
		)
	}

}
export default Input;
