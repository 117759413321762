import React from "react";
import Prismic from "prismic-javascript";
import {Elements, RichText} from 'prismic-reactjs'
import {ReactComponent as Tick} from '../../svg/colored/tick-white.svg';


import './_footer-box.scss';
import {Button} from "../index";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading2:
			props = {className: 'try-for-free-box__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'try-for-free-box__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};



const FooterBox = (props) => {
	const [doc, setDocData] = React.useState(null)
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'footer_box'),
				{lang: lang}
			)
			if (response) {
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang])
	if (doc) {
		let items = []
		for (let i = 0; i < 4; i++) {
			items.push(doc.data.box_items);
		}
		return(
			<section className="try-for-free">
				<div className="container">
					<div className="try-for-free-box">
						<div className="row align-items-center">
							<div className="col-md-5 col-lg-7">
								<div className="try-for-free-box__content">
									<RichText render={doc.data.box_title} htmlSerializer={htmlSerializer}/>
									<RichText render={doc.data.box_description} htmlSerializer={htmlSerializer}/>
									<Button className={'justify-content-center justify-content-md-start'} href={doc.data.box_button_link}>{RichText.asText(doc.data.box_button_text)}</Button>
								</div>
							</div>
							<div className="col-md-6 offset-md-1 col-lg-5 offset-lg-0">
								<div className="try-for-free-box__carousel">
									<div className="try-for-free-box__carousel__items">
										{items.map((list, key) => {
											return(
												<div key={key}>
													{
														list.map((elem,key) => {
															return(
																<div key={key} className="try-for-free-box__carousel__items__single">
																	<div
																		className="try-for-free-box__carousel__items__single__icon">
																		<Tick/>
																	</div>
																	<div
																		className="try-for-free-box__carousel__items__single__label">
																		{RichText.asText(elem.item)}
																	</div>
																</div>
															)
														})
													}
												</div>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
	return null
}
export default FooterBox;
