import React from "react";
import Prismic from "prismic-javascript";
import {RichText} from "prismic-reactjs";
import './login.scss';
import {Input} from "../../components";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../actions";
import {Link} from "react-router-dom";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const Login = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'login'),
				{lang: lang}
			)
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang, dispatch])
	if (doc) {
		return (
			<main className="main-content main-login" id="main-content">
				<div className="container">
					<div className="login-logo">
						<Link to="/">
							<img src={doc.data.logo.url} alt={doc.data.logo.alt}/>
						</Link>
					</div>
					<div className="login-form">
						<div className="login-form__title h2">
							{RichText.asText(doc.data.form_title)}
						</div>
						<form>
							<Input
							id={'login-email'}
							placeholder={'Your email address'}
							type={'email'}
							className={'input-group--icon input-group--email'}
							/>
							<Input
								id={'login-password'}
								placeholder={'Your password'}
								type={'password'}
								className={'input-group--icon input-group--password'}
							/>
							<div className="btn-container flex-column justify-content-center">
								<a className="forgotten-password" href="/">
									{RichText.asText(doc.data.password_forgotten_text)}
								</a>
								<button className="btn" type="submit">
									Login
								</button>
							</div>
						</form>
					</div>
				</div>
			</main>
		)
	}
	return null;
}
export default Login;
