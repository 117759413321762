import React from "react";
import {Elements, RichText} from 'prismic-reactjs'
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading2:
			props = {className: 'h2 what-we-do__title'};
			return React.createElement('h1', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'what-we-do__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};
const IconsSection = (props) => {
	const language = useSelector(state => state.lang);
	return(
		<>
			<section className="what-we-do">
				<div className="container container--compact">
					<RichText render={props.section_title} htmlSerializer={htmlSerializer}/>
					<RichText render={props.section_description} htmlSerializer={htmlSerializer}/>
					<div className="what-we-do__icons">

						<div className="row">
							{
								props.icons.map((icon, key) => {
									const pathName = (language === 'en-gb') ? '/en/features' : '/features';
									return(
										<div className="col-md-6 col-lg-4" key={key}>
											<NavLink to={{
												pathname: pathName,
												hash: RichText.asText(icon.slug)
											}} className="what-we-do__icon">
												<div className="what-we-do__icon__img" style={{backgroundColor: icon.icon_color}}>
													<img src={icon.icon_file.url} alt={icon.icon_file.alt}/>
												</div>
												<h3 className="what-we-do__icon__title h3">
													{RichText.asText(icon.icon_title)}
												</h3>
												<p className="what-we-do__icon__description">
													{RichText.asText(icon.icon_description)}
												</p>
											</NavLink>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default IconsSection;
