import React from "react";
import {useDispatch} from "react-redux";
import Prismic from "prismic-javascript";
import {changeLanguage} from "../../actions";
import {RichText} from "prismic-reactjs";
import './terms.scss';
import Footer from "../../templates/Footer/Footer";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const Terms = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.getByUID('docs', props.uid ? props.uid : 'terms-of-use')
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response);
			}
		}
		fetchData()
	}, [lang, dispatch, props.uid])
	if (doc) {
		return(
			<>
				<main className={'main-content main-terms'}>
					<div className="container">
						<div className="terms">
							<div className="terms__title h1">
								{RichText.asText(doc.data.page_title)}
							</div>
							<div className="terms__content">
								{RichText.render(doc.data.page_content)}
							</div>
						</div>
					</div>
				</main>
				<Footer lang={props.lang} className={'contact-footer'}/>
			</>
		)
	}
	return null;

}
export default Terms;
