import React from 'react';
import {Button} from "../../components";
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading2:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'section__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};

const CirclesSection = (props) => {
	return(
		<section className="section section--circles">
			<div className="container container--compact">
				<div className="row align-items-center">
					<div className="col-md-5">
						<div className="section__content">
							<div className="section__content__icon" style={{backgroundColor: props.color}}>
								<img src={props.icon.url} alt={props.icon.alt}/>
							</div>
							<RichText render={props.section_title} htmlSerializer={htmlSerializer}/>
							<RichText render={props.section_description} htmlSerializer={htmlSerializer}/>
							<div className="section__content__list">
								{props.list}
							</div>
							<Button className={'justify-content-center justify-content-md-start'} icon={true} href={props.buttonUrl}>{props.buttonText}</Button>
						</div>
					</div>
					<div className="col-md-7">
						<div className="circles-motion ml-auto">
							<div className="circles-motion__circles">
								<div className="circles-motion__circles__single"></div>
								<div className="circles-motion__circles__single"></div>
								<div className="circles-motion__circles__single"></div>
							</div>
							<div className="circles-motion__logos__main">
								<img src={props.logo.url} alt={props.logo.alt}/>
							</div>
							<div className="circles-motion__logos">
								{
									props.logos.map((logo, key) => {
										return(
											<div key={key} className="circles-motion__logos__single">
												<img src={logo.circle_icon.url} alt={logo.circle_icon.alt}/>
											</div>
										)
									})
								}

							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
export default CirclesSection;
