import React, {useEffect} from "react";
import './section-features.scss'
import {useLocation} from 'react-router-dom';
import {Accord} from "../index";
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
export const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading1:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.heading2:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'section__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};

const SectionFeatures = (props) => {
	const ref = React.createRef();
	const location = useLocation();
	useEffect(() => {
		if (ref.current.id.length > 0 && (location.hash.substring(1) === ref.current.id)) {
			window.scrollTo(0, ref.current.offsetTop - 60);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return(
		<section ref={ref} className="section" id={props.id}>
			<div className="container">
				<div className="section-inner">
					<div className="row align-items-center flex-column-reverse flex-md-row">
						<div className="col-md-6 col-lg-7">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
						<div className="col-md-6 col-lg-5">
							<div className="section__content">
								<div className="section__content__icon" style={{backgroundColor: props.color}}>
									<img src={props.icon.url} alt={props.icon.alt}/>
								</div>
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<Accord accordion={props.accordion}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionFeaturesReverse = (props) => {
	const ref = React.createRef();

	const location = useLocation();
	useEffect(() => {
		if (ref.current.id.length > 0 && (location.hash.substring(1) === ref.current.id)) {
			setTimeout(() => {
				window.scrollTo(0, ref.current.offsetTop - 60);
			}, 0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return(
		<section ref={ref} className="section section--reverse" id={props.id}>
			<div className="container">
				<div className="section-inner">
					<div className="row align-items-center">
						<div className="col-md-6 col-lg-5">
							<div className="section__content">
								<div className="section__content__icon" style={{backgroundColor: props.color}}>
									<img src={props.icon.url} alt={props.icon.alt}/>
								</div>
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<Accord accordion={props.accordion}/>
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionFeaturesWithBg = (props) => {
	const ref = React.createRef();
	const location = useLocation();
	useEffect(() => {
		if (ref.current.id.length > 0 && (location.hash.substring(1) === ref.current.id)) {
			window.scrollTo(0, ref.current.offsetTop - 60);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return(
		<section ref={ref} className="section section--with-bg" id={props.id}>
			<div className="container">
				<div className="section-inner" style={{backgroundColor: props.bg}}>
					<div className="row align-items-center flex-column-reverse flex-md-row">
						<div className="col-md-6 col-lg-7">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
						<div className="col-md-6 col-lg-5">
							<div className="section__content">
								<div className="section__content__icon" style={{backgroundColor: props.color}}>
									<img src={props.icon.url} alt={props.icon.alt}/>
								</div>
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<Accord accordion={props.accordion}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionFeaturesWithBgReverse = (props) => {

	const ref = React.createRef();
	const location = useLocation();
	useEffect(() => {
		if (ref.current.id.length > 0 && (location.hash.substring(1) === ref.current.id)) {
			window.scrollTo(0, ref.current.offsetTop - 60);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return(
		<section ref={ref} className="section section--with-bg section--reverse" id={props.id}>
			<div className="container">
				<div className="section-inner" style={{backgroundColor: props.bg}}>
					<div className="row align-items-center">
						<div className="col-md-6 col-lg-5">
							<div className="section__content">
								<div className="section__content__icon" style={{backgroundColor: props.color}}>
									<img src={props.icon.url} alt={props.icon.alt}/>
								</div>
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<Accord accordion={props.accordion}/>
							</div>
						</div>
						<div className="col-md-6 col-lg-7">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export {SectionFeatures, SectionFeaturesReverse, SectionFeaturesWithBg, SectionFeaturesWithBgReverse};
