import React from "react";
import {NavLink} from "react-router-dom";
import './404.scss';
const PageNotFound = () => {

	return(
		<main className="main-content main-404" id="main-content">
			<div className="container">
				<div className="not-found__heading h1">404</div>
				<div className="not-found__subheading">Page not found</div>
				<div className="btn-container justify-content-center">
					<NavLink to={'/'} className="not-found__return">Return to Homepage</NavLink>
				</div>
			</div>
		</main>
	)
}
export default PageNotFound;
