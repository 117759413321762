import menuOpener from "./menuOpener";
import lang from './lang';
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
	menuOpener: menuOpener,
	lang: lang,
});

export default rootReducer;
