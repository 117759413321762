import React from 'react';
import './homepage.scss';
import Prismic from "prismic-javascript";
import { RichText } from 'prismic-reactjs'
import HeroHeader from "./HeroHeader";
import IconsSection from "./IconsSection";
import CirclesSection from "./CirclesSection";
import {FooterBox, SectionHomePage} from "../../components";
import Footer from "../../templates/Footer/Footer";
import {useDispatch} from "react-redux";
import {changeLanguage} from "../../actions";

const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});


const HomePage = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'homepage'),
				{lang: lang}
			)
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang, dispatch])

	if (doc) {
		return (
			<>
				<main className={'main-content homepage'}>
					<HeroHeader
						title={doc.data.hero_title}
						description={doc.data.hero_description}
						image={doc.data.hero_image}
						buttonUrl={doc.data.hero_button_link}
						buttonText={RichText.asText(doc.data.hero_button_text)}
					/>
					<IconsSection
						section_title={doc.data.icons_title}
						section_description={doc.data.icons_description}
						icons={doc.data.icon}
					/>
					<CirclesSection
						color={doc.data.integration_color}
						icon={doc.data.integration_icon}
						section_title={doc.data.integration_title}
						section_description={doc.data.integration_description}
						list={RichText.render(doc.data.integration_list)}
						logo={doc.data.circle_logo}
						logos={doc.data.circle}
						buttonUrl={doc.data.integration_button_link}
						buttonText={RichText.asText(doc.data.integration_button_text)}
					/>
					{
						doc.data.sections.map((section, key) => {
							if (key % 2 === 0) {
								return(
									<SectionHomePage
										key={key}
										title={section.section_title}
										description={section.section_description}
										image={section.section_image}
										icon={section.section_icon.url}
										color={section.section_color}
										list={RichText.render(section.section_list)}
										buttonUrl={section.section_button_link}
										buttonText={RichText.asText(section.section_button_text)}
									/>
								)
							}
							return null;
						})
					}
				</main>
				<FooterBox lang={props.lang}/>
				<Footer lang={props.lang}/>
			</>
		)
	}
	return null;
}
export default HomePage;
