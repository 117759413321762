import React from "react";
import {Button} from "../index";
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading2:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'section__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};
const SectionHomePage = (props) => {
	return(
		<section className="section">
			<div className="container">
				<div className="section-inner">
					<div className="row align-items-center flex-column-reverse flex-md-row">
						<div className="col-md-6">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section__content">
								<div className="section__content__icon" style={{backgroundColor: props.color}}>
									<img src={props.icon} alt={RichText.asText(props.title)}/>
								</div>
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<div className="section__content__list">
									{props.list}
								</div>
								<Button className={'justify-content-center justify-content-md-start'} icon={true} href={props.buttonUrl}>{props.buttonText}</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
export default SectionHomePage;
