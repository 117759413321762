import React, {useEffect} from 'react';
import {
	Route,
	useLocation,
	Switch,
	Redirect
} from 'react-router-dom';
import NavBar from "./templates/NavBar/NavBar";
import {HomePage, Features, Solutions, Contact, Login, PageNotFound, Terms} from "./pages";
import {CSSTransition, TransitionGroup} from 'react-transition-group'


export function ScrollToTop() {
	const { pathname, hash } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname, hash]);

	return null;
}
const RedirectNotFound = () => <Redirect to={'/404'}/>
const App = () => {
	let location = useLocation();
	const routes = [
		{ path: '/', name: 'Home', Component: HomePage },
		{ path: '/features', name: 'Features', Component: Features },
		{ path: '/solutions', name: 'Solutions', Component: Solutions },
		{ path: '/contact', name: 'Contact', Component: Contact },
		{ path: '/login', name: 'Login', Component: Login },
		{ path: '/terms-of-use', name: 'Terms of use', Component: Terms },
		{ path: '/privacy-and-policy', name: 'Privacy and Policy', Component: Terms, uid: 'privacy-and-policy' }
	]
	const routesEn = [
		{ path: '/en', name: 'Home', Component: HomePage },
		{ path: '/en/features', name: 'Features', Component: Features },
		{ path: '/en/solutions', name: 'Solutions', Component: Solutions },
		{ path: '/en/contact', name: 'Contact', Component: Contact },
		{ path: '/en/login', name: 'Login', Component: Login },
		{ path: '/en/terms-of-use', name: 'Terms of use', Component: Terms },
		{ path: '/en/privacy-and-policy', name: 'Privacy and Policy', Component: Terms, uid: 'privacy-and-policy' }
	]
	return(
		<>
			<ScrollToTop />
			<NavBar/>
			<TransitionGroup>
				<CSSTransition
					key={location.key}
					timeout={600}
					classNames="page"
					unmountOnExit
				>
					<Switch location={location}>
						{
							routes.map(({ path, Component, uid }) => (
								<Route key={path} exact path={path}>
									<div className="page">
										<Component lang={'nl'} uid={uid}/>
									</div>
								</Route>
							))
						}
						{
							routesEn.map(({ path, Component, uid }) => (
								<Route key={path} exact path={path}>
										<div className="page">
											<Component lang={'en'} uid={uid} />
										</div>
									)}
								</Route>
							))
						}
						<Route path={'/404'} component={PageNotFound} />
						<Route component={RedirectNotFound} />
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		</>
	)
}

export default App;
