const menuOpener = (state = false, action) => {
	switch (action.type) {
		case 'TOGGLE_ACTION':
			state = !state;
			return state;

		default:
			return state;
	}
}

export default menuOpener;
