import React from 'react';
import { hydrate, render } from "react-dom";
import {BrowserRouter as Router} from 'react-router-dom';
import './global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {createStore} from 'redux';
import rootReducer from "./reducers";
import WebFont from 'webfontloader';
import TagManager from 'react-gtm-module'

const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
WebFont.load({
	custom: {
		families: ['CircularStd'],
		urls: ['/fonts/fonts.css']
	}
});

const tagManagerArgs = {
	gtmId: 'GTM-5VLMKNB'
}

TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
	hydrate(<Provider store={store}>
		<Router>
			<App/>
		</Router>
	</Provider>, rootElement);
} else {
	render(<Provider store={store}>
		<Router>
			<App/>
		</Router>
	</Provider>, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
