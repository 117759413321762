export const openMenu = () => {
	return ({
		type: 'TOGGLE_ACTION'
	})
}
export const changeLanguage = (type) => {
	return ({
		type: type
	})
}
