import React from "react";
import './section-features.scss';
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
export const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading1:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.heading2:
			props = {className: 'section__content__title h2'};
			return React.createElement('h2', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'section__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};

const SectionSolutions = (props) => {

	return(
		<section className="section section--basic">
			<div className="container">
				<div className="section-inner">
					<div className="row align-items-center flex-column-reverse flex-md-row">
						<div className="col-md-6">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section__content">
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionSolutionsReverse = (props) => {
	return(
		<section className="section section--reverse section--basic">
			<div className="container">
				<div className="section-inner">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="section__content">
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionSolutionsWithBg = (props) => {

	return(
		<section className="section section--with-bg section--basic">
			<div className="container">
				<div className="section-inner" style={{backgroundColor: props.bg}}>
					<div className="row align-items-center flex-column-reverse flex-md-row">
						<div className="col-md-6">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section__content">
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
const SectionSolutionsWithBgReverse = (props) => {
	return(
		<section className="section section--with-bg section--reverse section--basic">
			<div className="container">
				<div className="section-inner" style={{backgroundColor: props.bg}}>
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="section__content">
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section__img">
								<img src={props.image.url} alt={props.image.alt}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export {SectionSolutions, SectionSolutionsReverse, SectionSolutionsWithBg, SectionSolutionsWithBgReverse};
