const lang = (state = 'nl-nl', action) => {
	switch(action.type) {
		case 'en-gb':
			state = 'en-gb'
			return state;
		case 'nl-nl':
			state = 'nl-nl'
			return state;
		default:
			return state;
	}
}
export default lang;
