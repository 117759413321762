import React, {useState} from "react";
import {Accordion, Card, useAccordionToggle} from "react-bootstrap";
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
export const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.paragraph:
			props = {className: 'section__content__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};

const Accord = (props) => {
	const [panelIndex, setPanelIndex] = useState();
	const ContextAwareToggle = ({ children, eventKey }) => {

		const decoratedOnClick = useAccordionToggle(
			eventKey,
			() => {
				setPanelIndex( eventKey === panelIndex ? null : eventKey )
			}
		);
		const customClass = (eventKey === panelIndex) ? "opened" : "closed";
		return (
			<button
				type="button"
				onClick={decoratedOnClick}
			>
				<div className={`card-header__icon ` + customClass}></div>
				{children}
			</button>
		);
	}

	return(
		<Accordion defaultActiveKey="0">
			{
				props.accordion.map((elem, index) => {
					return(
						<Card key={index}>
							<Card.Header>
								<ContextAwareToggle variant="link" eventKey={index}>
									{RichText.asText(elem.section_accordion_label)}
								</ContextAwareToggle>
							</Card.Header>
							<Accordion.Collapse eventKey={index}>
								<Card.Body>{RichText.render(elem.section_accordion_content)}</Card.Body>
							</Accordion.Collapse>
						</Card>
					)
				})
			}
		</Accordion>
	)
}
export default Accord;
