import React from "react";
import Prismic from "prismic-javascript";
import HeroHeader from "./HeroHeader";
import './solutions.scss'
import {
	FooterBox,
	SectionSolutions,
	SectionSolutionsReverse,
	SectionSolutionsWithBg,
	SectionSolutionsWithBgReverse
} from "../../components";
import {RichText} from "prismic-reactjs";
import Footer from "../../templates/Footer/Footer";
import {changeLanguage} from "../../actions";
import {useDispatch} from "react-redux";
const apiEndpoint = 'https://companyscan.cdn.prismic.io/api/v2';
const Client = Prismic.client(apiEndpoint, {accessToken: 'MC5Yd3dxNGhFQUFDRUFxV0ow.77-977-977-9M--_ve-_ve-_ve-_vX0if--_ve-_vWHvv73vv73vv71T77-977-977-977-9Pu-_vXJP77-9b--_ve-_ve-_vWM'});

const Solutions = (props) => {
	const [doc, setDocData] = React.useState(null);
	const dispatch = useDispatch();
	let lang = 'nl-nl'
	switch(props.lang) {
		case 'en':
			lang = 'en-gb'
			break;
		default:
			lang = 'nl-nl'
			break;
	}
	React.useEffect(() => {
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at('document.type', 'solutions'),
				{lang: lang}
			)
			if (response) {
				dispatch(changeLanguage(lang));
				setDocData(response.results[0]);
			}
		}
		fetchData()
	}, [lang, dispatch])
	if (doc) {
		return (
			<>
				<main className={'main-content main-solutions'}>
					<HeroHeader
						title={doc.data.hero_title}
						description={doc.data.hero_description}
						image={doc.data.hero_image}
						buttonUrl={doc.data.hero_button_link}
						buttonText={RichText.asText(doc.data.hero_button_text)}
					/>
					{
						doc.data.body.map((slice, key) => {
							if (slice.slice_type === 'basic_section') {
								if (!slice.primary.image_position) {
									return(
										<SectionSolutions
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
										/>
									)
								} else {
									return(
										<SectionSolutionsReverse
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
										/>
									)
								}
							} else if (slice.slice_type === 'basic_section_with_background') {
								if (slice.primary.image_position) {
									return(
										<SectionSolutionsWithBgReverse
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
											bg={slice.primary.section_background}
										/>
									)
								} else {
									return(
										<SectionSolutionsWithBg
											key={key}
											no={key}
											title={slice.primary.section_title}
											description={slice.primary.section_description}
											image={slice.primary.section_image}
											icon={slice.primary.section_icon}
											color={slice.primary.section_color}
											accordion={slice.items}
											bg={slice.primary.section_background}
										/>
									)
								}
							}
							else {
								return null;
							}
						})
					}
				</main>
				<FooterBox lang={props.lang}/>
				<Footer lang={props.lang}/>
			</>
		)
	}
	return null;
}
export default Solutions;
