import React from "react";
import {Button} from "../../components";
import ProgressiveImage from "react-progressive-graceful-image";
import {Elements, RichText} from "prismic-reactjs";
const propsWithUniqueKey = function(props, key) {
	return Object.assign(props || {}, {key});
};
const htmlSerializer = function(type, element, content, children, key) {

	var props = {};
	switch(type) {
		case Elements.heading1:
			props = {className: 'hero-header__title h1'};
			return React.createElement('h1', propsWithUniqueKey(props, key), children);
		case Elements.paragraph:
			props = {className: 'hero-header__description'}
			return React.createElement('p', propsWithUniqueKey(props, key), children);
		default:
			return null;
	}
};

const HeroHeader = (props) => {

	return(
		<section className="hero-header">
			<div className="container">
				<div className="hero-header__wrapper">
					<div className="row align-items-center">
						<div className="col-12">
							<div className="hero-header__content">
								<RichText render={props.title} htmlSerializer={htmlSerializer}/>
								<RichText render={props.description} htmlSerializer={htmlSerializer}/>
								<Button className={"justify-content-center"} href={props.buttonUrl}>
									{props.buttonText}
								</Button>
							</div>
						</div>
						<div className="col-12">
							<div className="hero-header__img">
								<ProgressiveImage placeholder={props.image.static.url} src={props.image.url}>
									{src => <img src={src} alt={props.image.alt} />}
								</ProgressiveImage>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
export default HeroHeader;
