import React, {useEffect} from "react";
import {Elements, RichText} from "prismic-reactjs";
import {useLocation} from "react-router-dom";
const MiddleSection = (props) => {
	const ref = React.createRef();
	const location = useLocation();
	const propsWithUniqueKey = function(props, key) {
		return Object.assign(props || {}, {key});
	};
	const htmlSerializer = function(type, element, content, children, key) {

		var props = {};
		switch(type) {
			case Elements.heading2:
				props = {className: 'section__content__title h2'};
				return React.createElement('h2', propsWithUniqueKey(props, key), children);

			case Elements.paragraph:
				props = {className: 'section__content__description'};
				return React.createElement('p', propsWithUniqueKey(props, key), children);

			default:
				return null;
		}
	};

	useEffect(() => {
		if (ref.current.id.length > 0 && (location.hash.substring(1) === ref.current.id)) {
			window.scrollTo(0, ref.current.offsetTop - 60);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (props.children.length > 0) {
		return(
			<section className="section middle-section" style={{backgroundColor: props.background}} id={props.id} ref={ref}>
				<div className="container">
					<div className="section__content mx-auto d-flex flex-column align-items-center section__content--middle">
						{
							props.icon.url ? <div className="section__content__icon" style={{backgroundColor: props.color}}>
								<img src={props.icon.url} alt={props.icon.alt}/>
							</div> : ''
						}
						<RichText render={props.title} htmlSerializer={htmlSerializer}/>
						<RichText render={props.description} htmlSerializer={htmlSerializer}/>
					</div>
					<div className="middle-section__icons">
						<div className="row">
							{
								props.icons.map((icon, key) => {
									return(
										<div className="col-sm-4" key={key}>
											<div className="middle-section__icons__single">
												<div className="middle-section__icons__single__icon">
													<img src={icon.middle_section_icons_single.url} alt={icon.middle_section_icons_single.alt}/>
												</div>
												<div className="middle-section__icons__single__title">
													{RichText.asText(icon.middle_section_icon_single_title)}
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
					{props.children}
				</div>
			</section>
		)
	}
	return null;
}
export default MiddleSection;
