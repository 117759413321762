import React from "react";
import './button.scss'
import {ReactComponent as Arrow} from "../../svg/arrow.svg";
import {NavLink} from "react-router-dom";

const Button = (props) => {
	if (props) {
		let url = '#';
		if(props.href.link_type === 'Document') {
			if (props.href.lang === 'en-gb') {
				url = '/en/' + props.href.uid

			} else {
				url = '/' + props.href.uid
			}
		} else {
			url = props.href.url
		}
		return(
			<>
				<div className={`btn-container ${props.className}`}>
					<NavLink className={`btn ${props.icon ? 'btn--icon' : ''}`} to={url ? url : '#'}>{props.children ? props.children : 'Get in touch'}{props.icon ? <Arrow/> : ''}</NavLink>
				</div>
			</>
		)
	}
	return null;
}
export default Button;
